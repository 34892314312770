import "core-js/stable";
import "regenerator-runtime/runtime";

import "./app.scss";
import lazySizes from "lazysizes";
const simpleParallax = require("simple-parallax-js");
/* 
  To include modules, import and include them anywhere in your JS. For example, 
  uncommenting the following two lines will force the jquery chunk to be 
  generated. Normally, only code that is actually used will be output as modules.
*/
// import $ from "jquery"
// console.log( $ );

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
var image = document.getElementsByClassName("thumbnail");
new simpleParallax(image);

var imageTwo = document.getElementsByClassName("thumbnailTwo");
new simpleParallax(imageTwo, {
  orientation: "right",
});
document
  .getElementsByClassName("book-btn-nav")[0]
  .addEventListener("click", function () {
    var div2 = document.getElementsByClassName("booking")[0];
    div2.classList.toggle("active");
  });
document
  .getElementsByClassName("book-btn")[0]
  .addEventListener("click", function () {
    var div2 = document.getElementsByClassName("booking")[0];
    div2.classList.toggle("active");
  });
var closeButtons = document.getElementsByClassName("close-btn");

for (var i = 0; i < closeButtons.length; i++) {
  closeButtons[i].addEventListener("click", function () {
    var div2 = this.closest(".booking");
    if (div2) {
      div2.classList.remove("active");
    }
  });
}
